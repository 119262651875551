<template>
	<v-container fluid tag="section">
		<ti-card :icon="$icons.case_studies">
			<template #title>Case Studies</template>
			<template #title-actions-shown>
				<v-btn color="success" elevation="2" fab small :to="{name: 'CaseStudiesNew'}">
					<v-icon>{{ $icons.new }}</v-icon>
				</v-btn>
			</template>

			<v-data-table :headers="headers" :items="case_studies" item-key="name" :search="search">
				<template v-slot:top>
					<v-row justify="end">
						<v-col cols="12" lg="6">
							<v-text-field
								v-model="search"
								label="Search"
								prepend-icon="mdi-magnify"
								class="mx-4"
							></v-text-field>
						</v-col>
					</v-row>
				</template>

				<template v-slot:item.status="{ item }">
					<span>{{ item.status | capitalize }}</span>
				</template>

				<template v-slot:item.actions="{ item }">
					<v-btn icon @click="editArticle(item.id)">
						<v-icon>{{$icons.edit}}</v-icon>
					</v-btn>
				</template>

			</v-data-table>
		</ti-card>
	</v-container>
</template>

<script>
import {sync} from "vuex-pathify";
import _find from "lodash/find"
import helperMixin from "@/mixins/helperMixin";

export default {
	name: "CaseStudiesIndex",
	mixins: [helperMixin],
	computed: {
		case_studies: sync('case_studies/caseStudies'),
	},
	data: () => ({
		search: '',
		headers: [
			{
				text: 'Title',
				align: 'start',
				sortable: true,
				value: 'title',
			},
			{
				text: 'Status',
				align: 'start',
				sortable: true,
				value: 'status',
			},
			{
				text: '',
				align: 'end',
				sortable: false,
				value: 'actions',
			},
		]
	}),
	beforeMount() {
		this.init()
	},
	methods: {
		init() {
			this.$store.set('case_studies/getCaseStudies!', null)
		},
		editArticle(id) {
			this.$router.push({name: 'CaseStudiesEdit', params: {id}})
		},
		filterOnlyCapsText(value, search, item) {
			return value != null &&
				search != null &&
				typeof value === 'string' &&
				value.toString().toLocaleUpperCase().indexOf(search) !== -1
		},
	}
}
</script>